<template>
  <team-member-profile
    name="Gemma Brown"
    qualifications="BSc (Hons)"
    charter="MCSP"
    registration="HCPC Registered: PH73710"
    :pic="require(`@/assets/images/gemma.jpg`)"
  >
    <p>
      Gemma graduated from Southampton University in 2005 with a first class
      honours degree and worked within the NHS for 10 years. She has worked
      within the private sector since the beginning of 2014.
    </p>
    <p>
      Gemma's passion has always been within the specialism of neurology,
      gaining a broad range of experience in inpatient, outpatient and community
      settings. Gemma has been a team leader for The Community Stroke Team in
      Southampton. Whilst within this role she gained several awards for the
      quality care the team provided.
    </p>
    <p>
      Gemma is always striving to further her skills and has completed many post
      graduate courses including: Advanced Bobath Courses, Neurological
      hydrotherapy, PD Warrior (parkinsons disease exercise regime), Lycra
      assessment and provision, Myofascial Release, Taping and FES. She also has
      a special interest in vestibular rehabilitation.
    </p>
    <p>
      Gemma currently treats a variety of neurological conditions including
      brain injury, stroke, MS, Parkinsons and many others. She has worked with
      several solicitors and case managers with complex cases.
    </p>
    <p>
      Gemma works closely with her patients to reintegrate them into the local
      community and has close links with local gyms and groups.
    </p>
    <p>
      In her spare time Gemma enjoys gardening, is a parent governor at her
      twin's local school and teaches her children swimming.
    </p>
  </team-member-profile>
</template>

<script>
import TeamMemberProfile from "../../layouts/TeamMemberProfile.vue";
export default {
  name: "Gemma",
  components: {
    "team-member-profile": TeamMemberProfile,
  },
};
</script>
